<template>
    <div class="page_content">
        <div class="page_edit_content">
            <Form
                ref="form"
                @handleChange="handleChange"
                @handleSubmit="handleSubmit"
                :form_data="form_data"
            >
                <template slot="rule_user">
                    <TransferTree
                        @change="TransferTreeChange"
                        :treeData="treeData"
                        :value="treeDataValue"
                    />
                    <a-input hidden="true" v-decorator="['rule_user']"></a-input>
                </template>
                <!--  -->
                <template slot="menu">
                    <a-tree
                        v-model="app_keys"
                        checkable
                        v-decorator="['menu']"
                        :auto-expand-parent="false"
                        :selected-keys="app_select_keys"
                        :tree-data="menu"
                        @check="(event,info)=>{keys_check(event,info,'menu')}"
                    />
                </template>
                <!-- :selected-keys="selectedKeys" -->
                <!--  -->
                <template slot="app_menu">
                    <a-tree
                        checkable
                        v-model="app_menu_keys"
                        treeCheckable
                        :auto-expand-parent="false"
                        :selected-keys="app_menu_select_keys"
                        v-decorator="['app_menu']"
                        :tree-data="app_menu"
                        @load="tree_load"
                        @check="(event,info)=>{keys_check(event,info,'app_menu')}"
                    />
                </template>
            </Form>
        </div>
    </div>
</template>
<script>
import Form from "@/components/Form";
import { saveAuth, authEdit, authInfo } from '@/api/system'
import { post_user } from '@/api/api'
import TransferTree from "@/components/TransferTreeTwo";

export default {
    name: 'AuthEdit',
    components: {
        Form,
        TransferTree
    },
    data() {
        return {
            query: {},
            app_keys: [],
            app_menu_keys: [],
            app_select_keys: [],
            app_menu_select_keys: [],
            menu: [],
            app_menu: [],
            treeData: [],
            treeDataValue: [],
            form_data: {
                title: '添加',
                show_submit_btn: true,
                show_close_btn: true,
				close_reset_btn:true,
                list: [
                    {
                        type: 'text',
                        name: 'id',
                        hidden: true,
                        options: {},
                    },

                    {
                        type: "text",
                        name: "title",
                        title: "角色名称",
                        options: {}
                    },
                    {
                        type: "text",
                        name: "desc",
                        title: "角色描述",
                        options: {}
                    },
                    {
                        type: "radio",
                        name: "data_auth",
                        title: "数据查看范围",
                        relation_name: 'department',
                        options: {
                            initialValue: 0
                        },
                        list: [
                            {
                                key: 0,
                                value: '查看自己'
                            },
                            {
                                key: 1,
                                value: '查看自身部门及下属部门'
                            },
                            {
                                key: 2,
                                value: '查看指定及下属部门'
                            },
                            {
                                key: 3,
                                value: '查看所有'
                            }
                        ]
                    },
                    {
                        type: "tree-select",
                        name: "department",
                        close: true,
                        title: "部门",
                        options: {
                            rules: [
                                {
                                    required: true,
                                    message: "请选择部门!"
                                }
                            ],
                        },
                        treeData: [],
                        multiple: true
                    },
                    {
                        type: "slot",
                        name: "menu",
                        title: "后台权限",
                        options: {},
                    },
                    {
                        type: "slot",
                        name: "app_menu",
                        title: "手机端权限",
                        options: {},
                    },
                    {
                        type: "slot",
                        name: "rule_user",
                        title: "选择人员",
                        options: {},
                    },
                ]
            }
        }
    },
    created() {
        this.query = this.$route.query;

        this.$method.get_department().then(res => {
            this.form_data.list = this.$method.set_form_list(this.form_data.list, 'department', 'treeData', res)
        });

        authInfo().then(res => {
            this.menu = this.$method.get_depart_list(res.data.menu, 'children', 'id', 'title');
            this.app_menu = this.$method.get_depart_list(res.data.app_menu, 'children', 'id', 'title');
            // this.treeData = this.$method.get_user_department_list(res.data.role);
            post_user().then(res => {
                this.treeData = this.$method.get_user_department_list(res.data.list,'sysUserTree')    //方法参数值：sysUserTree
                // this.treeData = this.$method.get_user_department_list(res.data.list)


                if (this.query.id) {
                    this.form_data.title = '编辑'
                    authEdit({
                        data: {
                            id: this.query.id
                        }
                    }).then(res => {
                        this.form_data.list.forEach(item => {
    
                            if (item.name == 'menu') {
                                let { new_list } = this.exclude_ids(this.menu, res.data[item.name]);
    
                                this.app_keys = new_list; //res.data[item.name];
                                this.$refs.form.setFieldsValue({
                                    [item.name]: res.data[item.name].join(',')
                                })
                            } else if (item.name == 'app_menu') {
    
                                let { new_list } = this.exclude_ids(this.app_menu, res.data[item.name]);
    
                                this.app_menu_keys = new_list;//res.data[item.name];
                                this.$refs.form.setFieldsValue({
                                    [item.name]: res.data[item.name].join(',')
                                })
                            } else if (item.name == 'rule_user') {
    
                                let treeDataValue = [];
                                res.data[item.name].map(io => {
                                    treeDataValue.push("user_" + io.id)
                                    io.key = "user_" + io.id;
                                    io.title = io.username;
                                    return io;
                                });
                                // this.treeDataValue = treeDataValue;
                                
                                this.treeDataValue = JSON.parse(JSON.stringify(treeDataValue));
                          
                                this.$forceUpdate()
                                this.$refs.form.setFieldsValue({
                                    [item.name]: treeDataValue.join(',')
                                })
                            } else {
    
    
                                if (item.name == 'data_auth' && res.data[item.name] == 2) {
                                    let data = this.$method.get_form_relation_name(
                                        item,
                                        this.form_data.list,
                                        item.name
                                    );
                                    data.relation_name.close = false;
    
                                }
    
                                item.options.initialValue = res.data[item.name];
                            }
                            this.$forceUpdate()
                        })
                    })
                }
            })

        })
    },
    methods: {
        handleChange(data) {
            let name = "data_auth";
            let { e } = data;
            if (e.target.name == name) {
                data = this.$method.get_form_relation_name(
                    e.target,
                    this.form_data.list,
                    name
                );
                data.relation_name.close = e.target.value !== 2;
            }
        },
        keys_check(selectedKeys, { halfCheckedKeys }, name) {

            this.$refs.form.setFieldsValue({
                [name]: [...selectedKeys, ...halfCheckedKeys].join(',')
            })
        },

        exclude_ids(list, ids, new_list = []) {
            let is_exclude = false;
            for (let i = 0; i < list.length; i++) {
                if (Array.isArray(list[i].children) && list[i].children.length) {
                    let { res } = this.exclude_ids(list[i].children, ids, new_list)
                    if (ids.indexOf(list[i].key) > -1 && !res) {
                        new_list.push(list[i].key)
                    } else {
                        is_exclude = true;
                    }
                } else {
                    if (ids.indexOf(list[i].key) > -1) {
                        new_list.push(list[i].key)
                    } else {
                        is_exclude = true;
                    }
                }
            }
            return {
                res: is_exclude,
                new_list
            };
        },
        tree_load(keys, info) {
            
        },
        TransferTreeChange(value) {
            this.$refs.form.setFieldsValue({
                rule_user: value.join(',')
            });
        },
        handleSubmit(e) {
            let data = e.values;
            if (!data.id) {
                data.id = '';
            }
            if (Array.isArray(data.department)) {
                data.department = data.department.join(',')
            }


            saveAuth({
                data: {
                    ...data
                },
                info: true,
            }).then(res => {
                this.visible = false;
                this.$router.back();
            })
        }
    }
};
</script>
<style lang="less">
</style>
